

























import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, toCamelCase, showError, toSnakeCase } from 'utils'
import { Body, BottomSheetButton, NurseryCompany } from 'typings'
import { NurseryCompanyDialog, MasterItemComponent } from 'components'

interface SetUp {
  nurseryCompanies: Ref<NurseryCompany[]>
  showNurseryCompanyDialog: Ref<boolean>
  isAdd: Ref<boolean>
  nurseryCompanyProp: Ref<unknown>
  openNurseryCompanyDialog: (company: NurseryCompany) => void
  getNurseryCompanies: () => void
  confirmReset: (param: string) => void
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  saveSortOrder: (body: Body) => void
  bottomSheetListButton: Array<BottomSheetButton>
}

const NurseryCompanies = defineComponent({
  components: {
    NurseryCompanyDialog,
    MasterItemComponent
  },
  props: {},
  setup(props, { root }): SetUp {
    const nurseryCompanies: Ref<NurseryCompany[]> = ref([])
    const isSorting = ref(false)
    const nurseryCompanyProp = ref({})
    const showNurseryCompanyDialog = ref(false)
    const isAdd = ref(true)
    const { $toast } = root

    const loading = ref(false)

    const bottomSheetListButton: Array<BottomSheetButton> = [
      {
        id: 1,
        text: root.$t('master.nursery_company.add') as string,
        classes: [],
        icon: 'mdi-plus',
        action: 'on-add',
        disabled: false
      },
      {
        id: 2,
        text: root.$t('master.nursery_company.sort') as string,
        classes: [],
        icon: 'mdi-sort-ascending',
        action: 'on-sort',
        disabled: false
      },
      {
        id: 3,
        text: root.$t('common.cancel') as string,
        classes: ['red--text'],
        icon: '',
        action: 'on-close',
        disabled: false
      }
    ]

    const getNurseryCompanies = async (): Promise<void> => {
      loading.value = true
      try {
        const { data } = await api.get(endpoints.NURSERY_COMPANY)
        // need to convert result from snake_case to camelCase
        nurseryCompanies.value = toCamelCase(data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const saveSortOrder = async (body: Body): Promise<void> => {
      const params = toSnakeCase(body)
      try {
        await api.post(`${endpoints.NURSERY_COMPANY}sort`, params)
        $toast.success(root.$t('master.msg.save_order_successful'))
        isSorting.value = false
        await getNurseryCompanies()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.save_order_failed') as string)
      }
    }

    const openNurseryCompanyDialog = (company: NurseryCompany | null): void => {
      if (company) {
        nurseryCompanyProp.value = JSON.parse(JSON.stringify(company))
        isAdd.value = false
      } else {
        nurseryCompanyProp.value = {}
        isAdd.value = true
      }
      showNurseryCompanyDialog.value = true
    }

    const confirmReset = async (): Promise<void> => {
      $toast.info(root.$t('master.unit.unit_list_is_reset'))
      await getNurseryCompanies()
    }

    onMounted(getNurseryCompanies)

    return {
      nurseryCompanies,
      nurseryCompanyProp,
      showNurseryCompanyDialog,
      isAdd,
      isSorting,
      loading,
      openNurseryCompanyDialog,
      getNurseryCompanies,
      confirmReset,
      saveSortOrder,
      bottomSheetListButton
    }
  }
})

export default NurseryCompanies
